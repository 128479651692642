import { IoCall, IoMail, IoMap } from 'react-icons/io5';
import {
  ScColumnsResponsive,
  ScFlexAlignCenter,
  ScHeader3,
  ScImage,
  ScMaxWidthContainer,
  ScMaxWidthContainerWide,
} from './../components/styled-components';

import Image from '../components/image';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScBlackContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Balance & Effizienz im Alltag" keywords={globals.keywords} />
    <ScBlackContainer>
      <ScMaxWidthContainer>
        <ScColumnsResponsive>
          <div style={{ flex: '1', paddingLeft: '1rem' }}>
            <ScHeader3>Mentaltraining</ScHeader3>
            <p>
              Die Kraft der Gedanken ist zwar vielen bekannt, die Möglichkeiten
              und Auswirkungen werden aber trotzdem von vielen unterschätzt.
              Gedanken bewegen! Durch Mentaltraining können verschiedene Effekte
              erreicht und individuelle Ziele verfolgt werden. Emotionale,
              kognitive und auch körperliche Fähigkeiten können durch
              Mentaltraining verbessert werden. Schlechte Gewohnheiten können
              durch Mentales Training abgelegt und durch bessere Alternativen
              ersetzt werden. Mentaltraining kann einfach erlernt werden.
            </p>
            <h3>Wo findet Mentaltraining Anwendung?</h3>
            <ul>
              <li>
                <h5>Stressbewältigung</h5>
                Mentale Gedankenreisen helfen aufkommenden Stress zu verhindern.
                Man lernt besser mit schwierigen Situationen umzugehen,
                belastbarer und leistungsfähiger zu werden.
              </li>
              <li>
                <h5>Angst</h5>
                Durch Visualisierung und mentales Training lernt man mit Angst
                besser umzugehen und zur Ruhe zu kommen.
              </li>
              <li>
                <h5>Selbstbewusstsein</h5>
                Durch die reine Vorstellung wie erfolgreich Sie im Leben sind,
                können Sie auch Ihren Selbstwert steigern, Hürden überwinden und
                mehr Selbstvertrauen gewinnen.
              </li>
              <li>
                <h5>Kognition</h5>
                Wir sprechen hier auch von Gehirnjogging. Es geht darum, das
                eigene Gehirn zu trainieren, die geistige Leistungsfähigkeit zu
                steigern und im Alter aufrechtzuerhalten.
              </li>
            </ul>
          </div>
          <ScImage
            style={{
              width: '30rem',
              height: '23rem',
              margin: '1rem',
            }}
            src="pictures/pexels-pavel-danilyuk-7776206.jpg"
          />
        </ScColumnsResponsive>
      </ScMaxWidthContainer>
    </ScBlackContainer>
  </Layout>
);

export default IndexPage;
